export const HOW_IT_WORKS_PATH = '/how-it-works'
export const WHAT_IS_FLOCK_PATH = '/what-is-flock'
export const FAQ_PATH = '/faq'
export const STORIES_PATH = '/stories'
export const AGENTS_PATH = '/agents'
export const PORTFOLIO_PATH = '/portfolio'
export const ABOUT_US_PATH = '/about'
export const CAREERS_PATH = '/careers'
export const ONBOARDING_PATH = '/onboarding'
export const OFFER_PAGE_PATH = '/prelim-estimate'
export const CUSTOMER_OFFER_PAGE_PATH = `/portfolio-estimate`
export const HOMEPAGE_PATH = '/'
export const BLOG_PATH = '/blog'
export const RENT_SELL_FLOCK_CALCULATOR_PATH = '/rental-property-calculator'
export const INVEST_WITH_FLOCK_PATH = '/invest-with-flock'
